import { useEffect, useRef, useState } from "react";
import { Fade } from "./../../components/Animation";
import "animate.css";
import Select from "react-select";

import PlacesAutocomplete from "../Shared/PlacesAutocomplete";
import { FormProps } from ".";
import { useOrgTheme } from "../../context/ThemeContext";
import { BANNER_LAYOUT } from "../Home/Client/Banner";
import Switch from "../Common/Switch";

const DEFAULT_BTN_LABEL = "See My Design";

function EstimateFormSliderLayout({
  formik,
  onAddressChange,
  onAddressBlur,
  utilityProviderOptions,
  showSelectLoader,
  isSwitchOn = false,
  setSwitch = () => {},
  isSubmitDisabled
}: FormProps) {
  const { getHomeLayoutContent, isClientOrg, getOrgSettings } = useOrgTheme();
  const {
    formBtnLabel = DEFAULT_BTN_LABEL,
    formDisclaimer = "",
    type: layout,
  } = getHomeLayoutContent();
  const submitFormBtnText = isClientOrg ? formBtnLabel : DEFAULT_BTN_LABEL;
  const isLayoutTwo = layout === BANNER_LAYOUT.LAYOUT_2;
  const [step, setStep] = useState<number>(1);
  const { show_annual_kwh_toggle }: any = getOrgSettings();

  function handleNavClick(nav: "next" | "prev") {
    setStep(() => (nav === "next" ? 2 : 1));
  }

  const handleNextClick = ()=>{
    formik.setFieldValue("avg_monthly_bill","",true)
    handleNavClick("next")
  }
  const utilityProviderRef:any = useRef(null)
  useEffect(() => {
    if(!utilityProviderRef.current) return
    const controller = utilityProviderRef.current?.querySelector('.utility-provider__control');
    if (controller) {
      controller.id = 'utility-provider-select';
    }
  }, [utilityProviderRef?.current]);
  const placeholder = !isSwitchOn ? "Monthly Bill" : "Annual Kwh";
  return (
    <>
      <div className="grid w-full place-self-center md:text-center md:mt-8 mb-4 xd:px-10">
        <div className="w-full">
          <form
            id="create-project-form"
            className="home-banner-form shopnow-form text-left"
            onSubmit={formik.handleSubmit}
          >
            {step === 1 && (
              <>
                <div className="flex flex-col md:flex-row md:flex-nowrap min-w-full banner-form-slide">
                  <div className="relative w-full">
                    <PlacesAutocomplete
                      id='project-address-input'
                      showSvgIcon={true}
                      selectAddress={onAddressChange}
                      blur={onAddressBlur}
                      showFocus={false}
                      defaultInputValue={formik.values.address.full_address}
                    />
                    {formik.touched.address &&
                    formik.errors.address?.full_address ? (
                      <span className="text-red-500 text-xs sm:text-sm absolute left-0 w-full mt-0.5">
                        {formik.errors.address.full_address}
                      </span>
                    ) : (
                      formik.errors.address && (
                        <span className="text-red-500 text-xs sm:text-sm absolute left-0 w-full mt-0.5">
                          Please enter valid address
                        </span>
                      )
                    )}
                  </div>
                  <button
                    id="next-slide-btn"
                    type="button"
                    disabled={formik.errors.address || !formik.touched.address}
                    onClick={handleNextClick}
                    className={`customThemeButton mt-5 flex-1 md:mt-0 py-3 px-8 bg-primary text-base xxs:text-lg sm:text-xl  text-center whitespace-nowrap rounded md:rounded-l-none md:rounded-r  ${
                      isLayoutTwo ? "font-osBold" : "font-sfSemiBold"
                    } `}
                  >
                    {submitFormBtnText}
                  </button>
                </div>
                {formDisclaimer && (
                  <div
                    className="text-sm text-gray-500 text-left mt-5 homeBannerFormDisclaimer"
                    dangerouslySetInnerHTML={{ __html: formDisclaimer }}
                  ></div>
                )}
              </>
            )}

            {step === 2 && (
              <>
                <Fade triggerOnce>
                  <div className="animate__animated">
                    <div className="grid grid-cols-2 gap-x-4">
                      <div className="relative pb-6 col-span-2 md:col-span-1 input-bill-wrap">
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="absolute left-0 top-3 md:top-5 opacity-50 mx-1.5 md:mx-2 w-6 sm:w-6"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="1.5"
                              d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                            />
                          </svg>
                          <input
                            {...formik.getFieldProps("avg_monthly_bill")}
                            id='project-avg-monthly-bill'
                            name="avg_monthly_bill"
                            type="number"
                            placeholder={placeholder}
                            title="Enter Numeric Value"
                            className="average-monthly-bill sm:text-lg md:text-xl focus:outline-none focus:ring-titleColor pl-8 sm:pl-10 pr-3 p-2.5 sm:py-4 border border-gray-200 rounded w-full placeholder-current text-bodyColor"
                          />
                          {formik.touched.avg_monthly_bill &&
                          formik.errors.avg_monthly_bill ? (
                            <span className="text-red-500 text-xs sm:text-sm absolute bottom-1 left-0 w-full">
                              {formik.errors.avg_monthly_bill}
                            </span>
                          ) : null}
                        </div>
                        {show_annual_kwh_toggle && (
                          <div className="absolute top-3 md:top-5 right-3 flex gap-1 average-switch-toggle">
                            <span className="text-base">Annual Kwh</span>
                            <Switch
                              isOn={isSwitchOn}
                              onSwitchChange={setSwitch}
                              className="annaul-bill-switch"
                            />
                          </div>
                        )}
                      </div>

                      <div className="relative pb-6 col-span-2 md:col-span-1" ref={utilityProviderRef}>
                        <Select                        
                          className="select-box-wrap border border-gray-200 rounded xl:w-full w-full text-bodyColor text-sm bg-white"
                          id= "select"
                          options={utilityProviderOptions}
                          isLoading={showSelectLoader}
                          inputId="project-utility-provider"
                          name="utility_id"
                          value={
                            utilityProviderOptions?.find(
                              (option: any) =>
                                option.value === formik.values.utility_id
                            ) || ""
                          }
                          onChange={(option: any) =>
                            formik.setFieldValue("utility_id", option.value)
                          }
                          onBlur={() => {
                            formik.setFieldTouched("utility_id", true);
                          }}
                          classNamePrefix="utility-provider"
                        />
                        {formik.touched.utility_id &&
                        formik.errors.utility_id ? (
                          <span className="text-red-500 text-xs sm:text-sm absolute bottom-1 left-0 w-full">
                            {formik.errors.utility_id}
                          </span>
                        ) : null}
                      </div>
                    </div>
                    <div className="flex flex-row items-center justify-between sm:justify-end gap-x-4 sm:gap-x-6">
                      <button
                        id="back"
                        onClick={() => handleNavClick("prev")}
                        className={`md:mt-0  w-full xxs:w-auto py-3 px-6 border border-white text-white bg-trasparent text-base xxs:text-lg sm:text-xl font-sfMedium text-center whitespace-nowrap rounded ${
                          isLayoutTwo ? "bg-bodyColor" : "bg-trasparent"
                        }`}
                      >
                        Back
                      </button>
                      <button
                        id="create-project-btn"
                        type="submit"
                        className={`md:mt-0  w-full xxs:w-auto py-3 sm:py-3 px-6 font-sfMedium bg-primary border border-primary text-base xxs:text-lg sm:text-xl text-center whitespace-nowrap rounded ${
                          isLayoutTwo ? "text-white" : "text-titleColor"
                        }`}
                        disabled={isSubmitDisabled()}
                      >
                        See Recommendation
                      </button>
                    </div>
                  </div>
                </Fade>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default EstimateFormSliderLayout;
