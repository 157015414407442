import { useState } from "react";

const Switch = (props: any) => {
  const { onSwitchChange, isOn = false, className="" } = props;
  const [isSwitchOn, setSwitchOn] = useState(isOn);
  const handleSwitchChange = async () => {
    try{
      setSwitchOn((prev: boolean) => {
        return !prev;
      });
      onSwitchChange && onSwitchChange(!isSwitchOn);
    }catch(err){
      console.log(err)
    }
  };
  return (
    <label className={`spl__switch ${className} ${isSwitchOn ? "checked" : ""}`} id="kwh-toggle-btn">
      <input
        className={isSwitchOn ? "" : ""}
        type="checkbox"
        onChange={handleSwitchChange}
        checked={isSwitchOn}
      />
      <div className="switch-btn"></div>
      {/* <label htmlFor="btn"></label> */}
    </label>
  );
};
export default Switch;
