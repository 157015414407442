import { useEffect, useRef, useState } from "react";
import { useOrgTheme } from "../../context/ThemeContext";
import PlacesAutocomplete from "../Shared/PlacesAutocomplete";
import Select from "react-select";
import { FormProps } from ".";
import PrivacyPolicyRoute from "../Common/PrivacyPolicyRoute";
import Switch from "../Common/Switch";

const DISCLAIMER_TEXT =
  "By submitting this form, you consent to receive emails, text messages and phone calls at any phone number you provide, even if the number is a wireless number.You agree that such calls and texts may be made using automated technology and that you are not required to provide consent to these calls to make a purchase from us.";

function EstimateFormLayout({
  formik,
  onAddressChange,
  onAddressBlur,
  utilityProviderOptions,
  showSelectLoader,
  isSwitchOn,
  setSwitch,
  isSubmitDisabled
}: FormProps) {
  const { isClientOrg, getHomeLayoutContent,getOrgSettings } = useOrgTheme();
  const {show_annual_kwh_toggle}:any = getOrgSettings()
  const { formHeading, formDescription, formBtnLabel, formDisclaimer } =
    getHomeLayoutContent();
  const [toggleMobileformInfo, setToggleMobileformInfo] =
    useState<boolean>(false);

  const toggleformInfo = () => {
    setToggleMobileformInfo((prev) => !prev);
  };

  const utilityProviderRef:any = useRef(null)
  useEffect(() => {
    if(!utilityProviderRef.current) return
    const controller = utilityProviderRef.current?.querySelector('.utility-provider__control');
    if (controller) {
      controller.id = 'utility-provider-select';
    }
  }, [utilityProviderRef?.current]);
  const placeholder = !isSwitchOn ? "Monthly Bill" : "Annual Kwh";
  return (
    <>
      <div
        className={`${
          isClientOrg
            ? ""
            : "bg-white rounded-md w-full max-w-48 shadow xxs:shadow-lg-g"
        }`}
      >
        <form
          id="create-project-form"
          onSubmit={formik.handleSubmit}
          className="pb-4 pt-4 md:pt-8 px-6 2md:px-10"
        >
          {isClientOrg ? (
            <>
              <h2
                className={`break-words text-2xl md:text-2xl 2xl:text-3xl text-titleColor font-sfBold mb-2 xxs:mb-0.5`}
              >
                {formHeading}
              </h2>
              <p className="break-words text-base md:leading-snug 2xl:text-xl text-titleColor font-sfRegular mb-3 xxs:mb-4">
                {formDescription}
              </p>
            </>
          ) : (
            <h2
              className={`text-2xl xxxs:text-3xl text-titleColor font-configSemiBold mb-3 xxs:mb-6`}
            >
              Get Your Instant Solar Quote
            </h2>
          )}
          <div className="grid xxs:mb-1">
            <div className="relative pb-6 input-autocomplete-custom">
              <PlacesAutocomplete
                id='project-address-input'
                showSvgIcon={true}
                selectAddress={onAddressChange}
                blur={onAddressBlur}
                defaultInputValue={formik.values.address.full_address}
              />
              {formik.touched.address && formik.errors.address?.full_address ? (
                <span className="text-red-500 text-xs absolute bottom-1 left-0 w-full">
                  {formik.errors.address.full_address}
                </span>
              ) : (
                formik.errors.address && (
                  <span className="text-red-500 text-xs absolute bottom-1 left-0 w-full">
                    Please enter valid address
                  </span>
                )
              )}
            </div>
            <div className="relative pb-6">
              <div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="absolute left-0 top-3 md:top-4 opacity-50 mx-2 md:mx-3 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z"
                  />
                </svg>

                <input
                  {...formik.getFieldProps("avg_monthly_bill")}
                  id='project-avg-monthly-bill'
                  name="avg_monthly_bill"
                  placeholder={placeholder}
                  title="Enter Numeric Value"
                  type="number"
                  className="text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-titleColor pl-10 sm:pl-12 pr-3 p-3 border-2 border-gray-200 rounded w-full placeholder-current text-bodyColor"
                />
                {formik.touched.avg_monthly_bill &&
                  formik.errors.avg_monthly_bill && (
                    <span className="text-red-500 text-xs absolute bottom-1 left-0 w-full">
                      {formik.errors.avg_monthly_bill}
                    </span>
                  )}
              </div>
             {show_annual_kwh_toggle && <div className="absolute top-3.5 right-4 flex gap-1">
                <span className="text-base">
                  Annual Kwh
                </span>
                <Switch
                  isOn={isSwitchOn}
                  onSwitchChange={setSwitch}
                  className="annaul-bill-switch"
                />
              </div>}
            </div>

            <div className="relative pb-6" ref={utilityProviderRef}>
              <Select
                id= "select"
                options={utilityProviderOptions}
                isLoading={showSelectLoader}
                className="select-box-wrap border-2 border-gray-200 rounded xl:w-full w-full text-bodyColor text-sm bg-white"
                inputId="project-utility-provider"
                name="utility_id"
                value={
                  utilityProviderOptions?.find(
                    (option: any) => option.value === formik.values.utility_id
                  ) || ""
                }
                onChange={(option) =>
                  formik.setFieldValue("utility_id", option.value)
                }
                onBlur={() => {
                  formik.setFieldTouched("utility_id", true);
                }}
                classNamePrefix="utility-provider"
              />
              {formik.touched.utility_id && formik.errors.utility_id ? (
                <span className="text-red-500 text-xs absolute bottom-1 left-0 w-full">
                  {formik.errors.utility_id}
                </span>
              ) : null}
            </div>
          </div>

          <div className="flex flex-row items-center">
            <button
              id="create-project-btn"
              type="submit"
              className={`break-all customThemeButton font-sfSemiBold block p-4 w-full rounded-full  shadow-lg  text-sm 2xl:text-base uppercase text-center`}
              disabled={isSubmitDisabled()}
            >
              {formBtnLabel}
            </button>
          </div>
        </form>

        {!isClientOrg && (
          <div className="md:pb-8 pb-6 px-6 md:px-10">
            <div className="sm:hidden font-primaryLight text-xs 2xl:text-xs text-gray-500 flex flex-col">
              <p className="flex flex-row flex-wrap justify-between w-full pb-2">
                <span className="italic pr-6 font-primaryMedium">
                  We will not sell your information
                </span>
                <span
                  className="text-gray-400 text-base px-2"
                  onClick={() => toggleformInfo()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className={`${
                      toggleMobileformInfo ? "-rotate-180" : "rotate-0"
                    }  h-4 w-4 transform`}
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </span>
              </p>
              {toggleMobileformInfo && (
                <p className="font-primaryLight text-xs 2xl:text-xs text-gray-400 flex flex-col">
                  {DISCLAIMER_TEXT}
                  <PrivacyPolicyRoute />{" "}
                </p>
              )}
            </div>
            <p className="hidden xxs:flex font-primaryLight text-xs 2xl:text-xs text-gray-400 flex-col">
              <span className="font-primaryMedium italic block">
                We will not sell your information.
              </span>
              {DISCLAIMER_TEXT}
              <PrivacyPolicyRoute />{" "}
            </p>
          </div>
        )}
        {isClientOrg && formDisclaimer && (
          <div
            className="md:pb-8 pb-6 px-6 md:px-10 fullFormDisclaimer"
            dangerouslySetInnerHTML={{ __html: formDisclaimer }}
          ></div>
        )}
      </div>
    </>
  );
}

export default EstimateFormLayout;
